import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Container = styled.div`
	text-align: center;
`;

const Index = () => (
	<Layout>
		<SEO title="Home" />
		<Container>
			<h1>Hello, have we met before?</h1>
			<p>Due to high demand for our services, we're accepting new enquiries on a referral only basis.</p>
			<p>Do you know someone we have worked with? If so, please drop us an email or message on Twitter.</p>
		</Container>
	</Layout>
);

export default Index;
